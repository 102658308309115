import { useEffect, useState } from "react";
import { getLmsLoginAccessToken } from "../../../services/contentService";
import Parse from "parse";

export default function MenuSection({ menuItems }) {
  const [lmsLoginAccessToken, setLmsLoginAccessToken] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const result = await getLmsLoginAccessToken();
        setLmsLoginAccessToken(result?.access_token);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);
  useEffect(() => {
    const user = Parse.User.current();
    if (user) {
      setCurrentUser(user.toJSON());
    }
  }, []);

  console.log(menuItems);

  return (
    <div className="bg-secondary-secondary-bg" data-testid="menu-section">
      <main className="flex-row w-full px-1 py-12 mx-auto max-w-7xl md:py-24 justify-evenly sm:flex sm:justify-center">
        <div className="grid justify-between w-full grid-cols-2 gap-12 md:grid-cols-3 lg:grid-cols-6 lg:gap-0 menu-item">
          {currentUser?.siteId === "bayi" &&
            <div className="flex flex-col items-center mb-3">
              <a
                href={`https://paydas.kalekampus.com/portal?token=${lmsLoginAccessToken}`}
                className="space-y-6"
                target="_blank"
                rel="noreferrer"
              >
                <p
                  className="w-32 h-32 mx-auto link-icon-wrapper"
                  style={{ backgroundColor: "#a563cf" }}
                >
                  <img
                    className="w-28 h-28"
                    alt={`menu-icon-constant`}
                    src="/assets/img/menu-item.svg"
                  />
                </p>
                <p className="text-center focus:outline-none">EĞİTİM VİDEOLARI</p>
              </a>
            </div>
          }

          {menuItems?.map((item, index) => (
            <div className="flex flex-col items-center mb-3" key={index}>
              <a href={item?.icon_url} className="space-y-6">
                <p
                  className="w-32 h-32 mx-auto link-icon-wrapper"
                  style={{ backgroundColor: item?.icon_background }}
                >
                  <img
                    src={item.icon.url}
                    className="w-28 h-28"
                    alt={`menu-icon-${index}`}
                  />
                </p>
                <p tabIndex="0" className="text-center focus:outline-none">
                  {item?.icon_text}
                </p>
              </a>
            </div>
          ))}
        </div>
      </main>
    </div>
  );
}
