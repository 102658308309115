import classNames from "classnames";
import React, { useEffect, useState, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingModal from "../../components/shared/LoadingModal";
import Modal from "../../components/shared/Modal";
import AuthLayout from "../../layouts/AuthLayout";
import { getGifts, getNegativePoints, getPointInfo } from "../../services/contentService";
import ActivePoints from "./components/ActivePoints";
import EarnedPoints from "./components/EarnedPoints";
import NegativePoints from "./components/NegavitePoints";
import PointArrangement from "./components/PointArrangement";
import SpentPoints from "./components/SpentPoints";
import { SET_NEGATIVE_MANUEL_POINTS, SET_POINT_DATA, SET_EXPIRED_POINTS } from "../../store/app/appSlice";
import { useLocation } from "react-router-dom";
import Parse from "parse";
import { Helmet } from "react-helmet";

function Points() {
  const [activeTab, setActiveTab] = useState(1);
  const [gifts, setGifts] = useState(null);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  const pointData = useSelector((state) => state.app.pointData);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (location.state?.prevPath === "/kale-wall") {
      setActiveTab(3);
    }
  }, [location]);

  useEffect(() => {
    // Get currentUser from Parse
    const user = Parse.User.current();
    if (user) {
      setCurrentUser(user.toJSON());
    }
  }, []);

  const activeTabMarkup = () => {
    switch (activeTab) {
      case 1:
        return <ActivePoints pointData={pointData} siteId={currentUser?.siteId} />;
      case 2:
        return <SpentPoints gifts={gifts} siteId={currentUser?.siteId} />;
      case 3:
        return <PointArrangement />;
      case 4:
        return <EarnedPoints pointData={pointData} siteId={currentUser?.siteId} />;
      case 5:
        return <NegativePoints pointData={pointData} siteId={currentUser?.siteId} />;
      default:
        return;
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const gifts = await getGifts();
        setGifts(gifts);
        const negativePoints = await getNegativePoints();
        dispatch(
          SET_NEGATIVE_MANUEL_POINTS({
            ks: negativePoints.totalManuelKs,
            kk: negativePoints.totalManuelKK,
            total: negativePoints.totalManuelKs + negativePoints.totalManuelKK,
          })
        );

        const pointData = await getPointInfo();

        pointData.records.forEach((item) => {
          item.createdAt = item?.createdAt.toString();
          item.expiration_date = item?.expiration_date.toString();
          item.user = item?.user?.toJSON();
          item.giver = item?.giver?.toJSON();
          item.reason = item?.reason || null; // Add this line
        });

        pointData.groups = {
          ksPoint: 0,
          kkPoint: 0,
          survey: {
            kk: 0,
            ks: 0,
          },
          exam: {
            kk: 0,
            ks: 0,
          },
          beingOnline: {
            kk: 0,
            ks: 0,
          },
          manuel: {
            kk: 0,
            ks: 0,
          },
          liveBroadcast: {
            kk: 0,
            ks: 0,
          },
        };

        pointData.records.forEach((item) => {
          if (typeof item.kk_amount === "number") {
            pointData.groups.ksPoint += Number(item.ks_amount);
          }
          if (typeof item.ks_amount === "number") {
            pointData.groups.kkPoint += Number(item.kk_amount);
          }

          switch (item.resource) {
            case "Siteye Giriş":
              pointData.groups.beingOnline.kk += Number(item.kk_amount);
              pointData.groups.beingOnline.ks += Number(item.ks_amount);
              break;
            case "Anket":
              pointData.groups.survey.kk += Number(item.kk_amount);
              pointData.groups.survey.ks += Number(item.ks_amount);
              break;
            case "Bilgi Küpü":
              pointData.groups.exam.kk += Number(item.kk_amount);
              pointData.groups.exam.ks += Number(item.ks_amount);
              break;
            case "Manuel":
              if (item.type === "negative") break;
              pointData.groups.manuel.kk += Number(item.kk_amount);
              pointData.groups.manuel.ks += Number(item.ks_amount);
              break;
            case "Canlı Yayın":
              pointData.groups.liveBroadcast.kk += Number(item.kk_amount);
              pointData.groups.liveBroadcast.ks += Number(item.ks_amount);
              break;

            default:
              break;
          }
        });
        dispatch(SET_POINT_DATA(pointData));
        dispatch(SET_EXPIRED_POINTS(pointData.expire_points));
      } catch (error) {
        setApiError(error.message);
        setShowErrorModal(true);
      } finally {
        setLoading(false);
      }
    })();
    //eslint-disable-next-line
  }, []);
  console.log(currentUser);

  return (
    <>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-QFLY5D5WWK"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag("js", new Date());
            gtag("config", "G-QFLY5D5WWK");
          `}
        </script>
      </Helmet>
      <AuthLayout>
        {loading && <LoadingModal />}
        <Modal shouldShow={showErrorModal} updateModalState={(show) => setShowErrorModal(show)} msg={apiError} type="error" />
        <div id="content" className="">
          <div className="px-4 py-5 bg-background-teal md:px-4 lg:py-16 lg:px-0">
            <main className="px-4 mx-auto max-w-7xl lg:px-1">
              <div className="flex flex-col justify-between grid-cols-12 md:grid lg:items-center">
                <div className="col-span-7">
                  <h1 className="mb-4 text-md lg:text-xxl text-primary-black font-lg lg:mb-0">Kale Puan</h1>
                </div>
              </div>
            </main>
          </div>
          <div className="bg-white">
            <div className="max-w-5xl mx-auto">
              <div className="py-16">
                <div className="max-w-full mb-12 overflow-y-auto text-center">
                  <div className="relative z-0 inline-flex bg-secondary-secondary-bg mx-auto p-0.5">
                    <button
                      type="button"
                      onClick={() => setActiveTab(1)}
                      className={classNames(
                        activeTab === 1 ? "inline-block !bg-primary-red !text-white active" : "inline-block !text-primary-black !bg-transparent",
                        "w-full p-3 break-normal tab-btn text-primary-black hover:text-white max-w-max"
                      )}
                    >
                      Aktif Puanlarım
                    </button>
                    <button
                      type="button"
                      onClick={() => setActiveTab(2)}
                      className={classNames(
                        activeTab === 2 ? "inline-block !bg-primary-red !text-white active" : "inline-block !text-primary-black !bg-transparent",
                        "w-full p-3 break-normal tab-btn text-primary-black hover:text-white max-w-max"
                      )}
                    >
                      Harcadığım Puanlarım
                    </button>
                    <button
                      type="button"
                      onClick={() => setActiveTab(5)}
                      className={classNames(
                        activeTab === 5 ? "inline-block !bg-primary-red !text-white active" : "inline-block !text-primary-black !bg-transparent",
                        "w-full p-3 break-normal tab-btn text-primary-black hover:text-white max-w-max"
                      )}
                    >
                      Negatif Puanlarım
                    </button>
                    <button
                      type="button"
                      onClick={() => setActiveTab(4)}
                      className={classNames(
                        activeTab === 4 ? "inline-block !bg-primary-red !text-white active" : "inline-block !text-primary-black !bg-transparent",
                        "w-full p-3 tab-btn text-primary-black hover:text-white max-w-max"
                      )}
                    >
                      Kazandığım Puanlarım
                    </button>
                    {currentUser?.siteId !== "tali" && ( // Tali site ise gizle
                      <button
                        type="button"
                        onClick={() => setActiveTab(3)}
                        className={classNames(
                          activeTab === 3 ? "inline-block !bg-primary-red !text-white active" : "inline-block !text-primary-black !bg-transparent",
                          "w-full p-3 break-normal tab-btn text-primary-black hover:text-white max-w-max"
                        )}
                      >
                        Kale Puan Sıralamam
                      </button>
                    )}
                  </div>
                </div>
                {activeTabMarkup()}
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
}

export default memo(Points);
