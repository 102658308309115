import React, { memo, useEffect, useState } from "react";
import { getUserPointsRanking } from "../../../services/contentService";

function RewardArrangement() {
  const [userPointsRanking, setUserPointsRanking] = useState(null);

  useEffect(() => {
    (async () => {
      const userPointsRanking = await getUserPointsRanking();
      setUserPointsRanking(userPointsRanking);
    })();
  }, []);

  return (
    <div className="grid grid-cols-1 gap-20 px-4 lg:grid-cols-3 lg:px-0">
      <div className="flex flex-col items-center lg:block">
        <div className="flex flex-col items-center justify-center w-56 h-56 rounded-full bg-tertiary-purple">
          <p className="text-lg text-center text-white">
            {userPointsRanking?.GeneralPlace}
          </p>
          <p className="text-center text-white text-body-l">
            Genel <br />
            Sıralamada
          </p>
        </div>
        {userPointsRanking?.GeneralPointList?.map((generalPoint, index) => {
          return (
            <div
              className="flex pb-6 mt-12 border-b border-secondary-lines"
              key={index}
            >
              <h3 className="mr-3 text-lg font-lg">#{index + 1}</h3>
              {generalPoint.profile_image ? (
                <img
                  src={
                    generalPoint.profile_image._url ||
                    generalPoint.profile_image.url
                  }
                  alt="User"
                  className="w-[88px] h-[88px] rounded-full object-cover object-center"
                />
              ) : (
                <div className="relative flex items-center justify-center w-[88px] h-[88px] rounded-full bg-secondary-titles">
                  <span className="sr-only">User</span>
                  <svg role="img" className="w-10 h-10 text-white">
                    <use xlinkHref="/assets/img/necromancers.svg#user" />
                  </svg>
                </div>
              )}

              <div className="flex flex-col gap-1 ml-6">
                <p className="font-bold text-body-l text-primary-black">
                  {generalPoint.name}
                </p>
                <p className="text-body-s">{generalPoint.address}</p>
                <p className="font-bold text-body">
                  {generalPoint?.totalPoints?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex flex-col items-center lg:block">
        <div className="flex flex-col items-center justify-center w-56 h-56 rounded-full bg-tertiary-blue">
          <p className="text-lg text-center text-white">
            {userPointsRanking?.CityPlace}
          </p>
          <p className="text-center text-white text-body-l">
            Şehir Bazında <br />
            Sıralamada
          </p>
        </div>
        {userPointsRanking?.CitiesPointList?.map((cityPoint, index) => (
          <div
            className="flex pb-6 mt-12 border-b border-secondary-lines"
            key={index}
          >
            <h3 className="mr-3 text-lg font-lg">#{index + 1}</h3>
            {cityPoint.profile_image ? (
              <img
                src={
                  cityPoint.profile_image._url || cityPoint.profile_image.url
                }
                alt="User"
                className="w-[88px] h-[88px] rounded-full object-cover object-center"
              />
            ) : (
              <div className="relative flex items-center justify-center w-[88px] h-[88px] rounded-full bg-secondary-titles">
                <span className="sr-only">User</span>
                <svg role="img" className="w-10 h-10 text-white">
                  <use xlinkHref="/assets/img/necromancers.svg#user" />
                </svg>
              </div>
            )}

            <div className="flex flex-col gap-1 ml-6">
              <p className="font-bold text-body-l text-primary-black">
                {cityPoint.name}
              </p>
              <p className="text-body-s">{cityPoint.city}</p>
              <p className="font-bold text-body">
                {cityPoint.totalPoints
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="flex flex-col items-center lg:block">
        <div className="flex flex-col items-center justify-center w-56 h-56 rounded-full bg-primary-green">
          <p className="text-lg text-center text-white">
            {userPointsRanking?.FirmPlace}
          </p>
          <p className="text-center text-white text-body-l">
            Firma Bazında <br />
            Sıralamada
          </p>
        </div>
        {userPointsRanking?.FirmPointList?.map((firmPoint, index) => (
          <div
            className="flex pb-6 mt-12 border-b border-secondary-lines"
            key={index}
          >
            <h3 className="mr-3 text-lg font-lg">#{index + 1}</h3>
            {firmPoint.profile_image ? (
              <img
                src={
                  firmPoint.profile_image._url || firmPoint.profile_image.url
                }
                alt="User"
                className="w-[88px] h-[88px] rounded-full object-cover object-center"
              />
            ) : (
              <div className="relative flex items-center justify-center w-[88px] h-[88px] rounded-full bg-secondary-titles">
                <span className="sr-only">User</span>
                <svg role="img" className="w-10 h-10 text-white">
                  <use xlinkHref="/assets/img/necromancers.svg#user" />
                </svg>
              </div>
            )}

            <div className="flex flex-col gap-1 ml-6">
              <p className="font-bold text-body-l text-primary-black">
                {firmPoint.name}
              </p>
              <p className="text-body-s">{firmPoint.city}</p>
              <p className="font-bold text-body">
                {firmPoint.totalPoints
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
export default memo(RewardArrangement);
