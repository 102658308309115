import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import InputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import CustomSelect from "../../components/shared/CustomSelect";
import classNames from "classnames";
import { registerBayi, getCities, getRegions, getBayiTitles, getBayiFirms, getBayiBranchesRegister } from "../../services/authService";
import { useForm, Controller } from "react-hook-form";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";
import KvkkPolicyModal from "../../components/shared/KvkkPolicyModal";
import { getRegisterContent } from "../../services/contentService";
import Modal from "../../components/shared/Modal";
import LoadingModal from "../../components/shared/LoadingModal";
import { getDate, subYears } from "date-fns";
import ForgotPassword from "../NonLoginHomePage/components/ForgotPassword";
import { Helmet } from "react-helmet";

function RegisterPage() {
  // register locale for date picker
  registerLocale("tr", tr);
  const { register, handleSubmit, control, watch, setValue, getValues, formState } = useForm({
    mode: "onBlur",
  });
  const watchCity = watch("user.city");
  const watchFirm = watch("user.firm");
  const [cities, setCities] = useState([]);
  const [regions, setRegions] = useState([]);
  const [titles, setTitles] = useState([]);
  const [content, setContent] = useState(null);
  const [firms, setFirms] = useState([]);
  const [branches, setBranches] = useState([]);
  const [errorModal, setErrorModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [kvkkModalOpen, setKvkkModalOpen] = useState(false);
  const [kvkkActiveTab, setKvkkActiveTab] = useState(1);
  //invalid email or phone, open model for forgot password.
  const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [dealerType, setDealerType] = useState("bayi");
  const graduationOptions = [
    {
      active: true,
      defaultValue: false,
      label: "İlkokul",
      validFor: null,
      value: "Primary School Degree",
    },
    {
      active: true,
      defaultValue: false,
      label: "Orta Okul",
      validFor: null,
      value: "Middle School",
    },
    {
      active: true,
      defaultValue: false,
      label: "Lise",
      validFor: null,
      value: "High School Degree",
    },
    {
      active: true,
      defaultValue: false,
      label: "Teknik Lise",
      validFor: null,
      value: "Technical Highschool Degree",
    },
    {
      active: true,
      defaultValue: false,
      label: "Önlisans",
      validFor: null,
      value: "College Degree",
    },
    {
      active: true,
      defaultValue: false,
      label: "Lisans",
      validFor: null,
      value: "Bachelor Degree",
    },
    {
      active: true,
      defaultValue: false,
      label: "Yüksek Lisans",
      validFor: null,
      value: "Master Degree",
    },
    {
      active: true,
      defaultValue: false,
      label: "Doktora",
      validFor: null,
      value: "Doctorate",
    },

    {
      active: true,
      defaultValue: false,
      label: "Diğer",
      validFor: null,
      value: "Other",
    },
  ];

  let navigate = useNavigate();
  const handleSuccess = () => {
    navigate("/");
  };

  const sortByName = (a, b) => {
    return a.NAME.localeCompare(b.NAME);
  };

  useEffect(() => {
    if (isConfirmed) {
      setForgotPassword(true);
    }
  }, [isConfirmed]);

  useEffect(() => {
    getBayiTitles()
      .then((data) => {
        setTitles(data);
        // setValue("user.title", data[0].title);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getCities()
      .then((cities) => {
        const sortedCities = cities;
        sortedCities.sort((a, b) => sortByName(a, b));
        setCities(sortedCities);
        setValue("user.city", cities[0].ID);
      })
      .catch((err) => {
        console.log(err);
      });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const firmValue = getValues("user.firm");
    if (firmValue) {
      getBayiBranchesRegister(firmValue)
        .then((branch) => {
          setBranches(branch);
          // setValue("user.branch", branch[0]?.Id);
        })
        .catch((e) => {
          console.log(e);
        });
    }
    //eslint-disable-next-line
  }, [watchFirm]);

  useEffect(() => {
    const cityValue = getValues("user.city");
    getRegions(cityValue)
      .then((regions) => {
        const sortedRegions = regions;
        sortedRegions.sort((a, b) => sortByName(a, b));
        setRegions(sortedRegions);
        setValue("user.region", regions[0].ID);
      })
      .catch((err) => {
        console.log(err);
      });
    //eslint-disable-next-line
  }, [watchCity]);

  useEffect(() => {
    getBayiFirms(dealerType === "Tali" ? "tali" : "bayi")
      .then((data) => {
        setFirms(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dealerType]);

  useEffect(() => {
    setValue("user.firm", null);
    setFirms([]);
    setBranches([]);
  }, [dealerType, setValue]);

  const onSubmit = async (data) => {
    console.log("onSubmit", data);

    try {
      const originalPhoneNum = data.user.phoneNum;
      const kaleCompany = branches.find((branch) => branch.Id === data.user.branch);
      const kaleFirm = firms.find((firm) => firm.Id === data.user.firm);

      if (dealerType === "Tali") {
        data.user.placier = originalPhoneNum.replace(/([^0-9])+/g, "");
        data.user.ksEmailPermission = false;
        data.user.ksKvkkPermission = true;
      }

      const registerData = {
        ...data.user,
        phoneNum: `+9${data.user.phoneNum.replace(/([^0-9])+/g, "")}`,
        company: kaleCompany?.Type ? kaleCompany?.Type : kaleFirm.Type,
        City__r: cities.find((city) => city.ID === data.user.city)?.NAME,
        Region__r: regions.find((region) => region.ID === data.user.region)?.NAME,
        Billing_City__r: cities.find((city) => city.ID === data.user.city)?.NAME,
        BillingRegion__r: regions.find((region) => region.ID === data.user.region)?.NAME,
        Class__c: data.user.title,
      };

      if (data.user.birthday) {
        registerData.birthday = new Date(data.user.birthday.setHours(12)).toISOString();
      }

      setLoading(true);

      const result = await registerBayi(registerData);

      if (result.id) {
        setSuccessModal(true);
      } else {
        if (
          result.message.trim() === "Bu telefon numarası başka bir bayi tarafından kullanılmaktadır." ||
          result.message.trim() === "Bu e-posta adresi başka bir bayi tarafından kullanılmaktadır."
        ) {
          setErrorMsg(result.message);
          setForgotPasswordModal(true);
        } else {
          setErrorMsg(result.message);
          setErrorModal(true);
        }
      }
    } catch (error) {
      console.error("Bir hata oluştu:", error);
      setErrorMsg("Beklenmeyen bir hata oluştu. Lütfen tekrar deneyin.");
      setErrorModal(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const content = await getRegisterContent();
        setContent(content);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  if (kvkkModalOpen) {
    return (
      <KvkkPolicyModal kvkkActiveTab={kvkkActiveTab} kvkkModalOpen={kvkkModalOpen} setKvkkModalOpen={setKvkkModalOpen} setKvkkActiveTab={(tab) => setKvkkActiveTab(tab)} />
    );
  }

  return (
    <>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-QFLY5D5WWK"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag("js", new Date());
            gtag("config", "G-QFLY5D5WWK");
          `}
        </script>
      </Helmet>
      {loading && <LoadingModal />}
      {errorModal && <Modal type="error" msg={errorMsg} updateModalState={(state) => setErrorModal(state)} shouldShow={errorModal} />}
      {successModal && (
        <Modal
          handleAfterClose={handleSuccess}
          type="success"
          msg="Başarılı bir şekilde kayıt oldunuz. Sisteme giriş yapabilmek için bayi yetkilinizin onayı beklenmektedir. Bayi yetkilinizle görüşebilirsiniz."
          updateModalState={(state) => setSuccessModal(state)}
          shouldShow={successModal}
        />
      )}
      {forgotPasswordModal && (
        <Modal
          type="info"
          msg={errorMsg}
          secondMsg="Şifremi unuttum ile ilerleyiniz."
          updateModalState={(state) => setForgotPasswordModal(state)}
          shouldShow={forgotPasswordModal}
          setIsConfirmed={(state) => setIsConfirmed(state)}
        />
      )}

      {/*TODO: We don't have any cloud function for this. After we have, needts to be improved.*/}
      <ForgotPassword
        showForgotPasswordModal={forgotPassword}
        setIsConfirmed={(state) => setIsConfirmed(state)}
        setShowForgotPasswordModal={(state) => setForgotPassword(state)}
      />

      <div className="container flex flex-col items-center justify-center max-w-full px-4 pt-20 mx-auto bg-background-blue lg:px-0 lg:pt-0">
        <Link to="/" className="absolute p-2 right-6 top-6 bg-primary-black">
          <svg className="w-4 h-4 text-white link-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20.7504 4.75042L13.9445 11.5563L20.7504 18.3623L18.8059 20.3068L12 13.5008L5.19407 20.3068L3.24958 18.3623L10.0555 11.5563L3.24958 4.75042L5.19407 2.80593L12 9.61186L18.8059 2.80593L20.7504 4.75042Z"
              fill="currentColor"
            />
          </svg>
        </Link>

        <img src={content?.banner?.url} alt="register-img" data-testid="register-image" />

        <div className="items-start w-full px-1 py-8 mx-auto lg:pt-20 lg:pb-28 max-w-7xl">
          <div className="flex flex-col items-center justify-around flex-1 pb-2 lg:flex-row">
            <div className="flex flex-1 space-y-4">
              <h2 className="mb-8 text-lg lg:mb-0 lg:text-xxl">{content?.title}</h2>
            </div>
            <div className="flex flex-1 lg:justify-end text-justify">
              <p className="max-w-md text-xs ">{content?.text}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="relative py-20" id="content">
        <main className="max-w-4xl mx-auto">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col px-4 lg:flex-row lg:space-x-14 lg:px-0">
              <div className="flex-col justify-center flex-1 lg:px-1">
                <h3 className="text-sm text-center lg:text-md lg:text-left">Kişisel Bilgiler</h3>
                <div className="flex flex-col gap-8 mt-10">
                  <div
                    className={classNames("form-input-wrapper", {
                      error: formState?.errors?.user?.firstName,
                    })}
                  >
                    <input
                      {...register("user.firstName", {
                        required: true,
                      })}
                      type="text"
                      id="first-name"
                      autoComplete="cc-given-name"
                      placeholder=" "
                      className="bg-transparent form-input"
                      data-testid="name-input"
                    />
                    <label htmlFor="user.firstName" className="bg-transparent form-label">
                      Adınız*
                    </label>
                    {formState.errors?.user?.firstName && (
                      <svg role="img" className="w-5 h-5 text-validation-error absolute top-1/4 right-3">
                        <use xlinkHref="/assets/img/necromancers.svg#error" />
                      </svg>
                    )}
                    {formState.errors?.user?.firstName && (
                      <p role="alert" className="mt-2 text-validation-error" id="email-error">
                        Zorunlu alan.
                      </p>
                    )}
                  </div>
                  <div
                    className={classNames("form-input-wrapper", {
                      error: formState?.errors?.user?.lastName,
                    })}
                  >
                    <input
                      {...register("user.lastName", { required: true })}
                      type="text"
                      id="last-name"
                      autoComplete="cc-given-last-name"
                      placeholder=" "
                      className="bg-transparent form-input"
                      data-testid="last-name"
                    />
                    <label htmlFor="last-name" className="bg-transparent form-label">
                      Soyadınız*
                    </label>
                    {formState.errors?.user?.lastName && (
                      <svg role="img" className="w-5 h-5 text-validation-error absolute top-1/4 right-3">
                        <use xlinkHref="/assets/img/necromancers.svg#error" />
                      </svg>
                    )}
                    {formState.errors?.user?.lastName && (
                      <p role="alert" className="mt-2 text-validation-error" id="email-error">
                        Zorunlu alan.
                      </p>
                    )}
                  </div>
                  <div
                    className={classNames("form-input-wrapper", {
                      error: formState?.errors?.user?.gender,
                    })}
                  >
                    <select {...register("user.gender")} id="gender" autoComplete="gender" className="bg-transparent form-input">
                      <option disabled></option>
                      <option name="Erkek" value="Erkek">
                        Erkek
                      </option>
                      <option name="Kadın" value="Kadın">
                        Kadin
                      </option>
                    </select>
                    <label htmlFor="gender" className="bg-transparent form-label">
                      Cinsiyet
                    </label>
                  </div>
                  <div
                    className={classNames("form-input-wrapper", {
                      error: formState?.errors?.user?.tcId,
                    })}
                  >
                    <Controller
                      className="bg-transparent form-input "
                      control={control}
                      name="user.tcId"
                      render={({ field: { onChange, value } }) => (
                        <InputMask
                          {...register("user.tcId")}
                          // ref={nodeRef}
                          name="tcId"
                          type="tel"
                          id="tcId-mask"
                          mask="99999999999"
                          className="bg-transparent form-input tc"
                          placeholder=" "
                          onChange={(e) => onChange(e)}
                          value={value || ""}
                          maskplaceholder="_"
                        />
                      )}
                    />
                    <label htmlFor="tc-id" className="bg-transparent form-label">
                      TC Kimlik No*
                    </label>
                    {formState.errors?.user?.tcId && (
                      <svg role="img" className="w-5 h-5 text-validation-error absolute top-1/4 right-3">
                        <use xlinkHref="/assets/img/necromancers.svg#error" />
                      </svg>
                    )}
                    {formState.errors?.user?.tcId && (
                      <p className="mt-2 text-validation-error" id="email-error">
                        Zorunlu alan.
                      </p>
                    )}
                  </div>
                  <div
                    className={classNames("relative", {
                      error: formState?.errors?.user?.birthday,
                    })}
                  >
                    <Controller
                      data-testid="birthday-input"
                      control={control}
                      name="user.birthday"
                      render={({ field }) => (
                        <DatePicker
                          placeholderText="Doğum Tarihi"
                          className="bg-transparent  datepicker form-input-date border-gray-300 focus:border-gray-600"
                          dateFormat="dd/MM/yyyy"
                          selected={field.value}
                          onChange={(date) => {
                            field.onChange(date);
                          }}
                          locale={tr}
                          todayButton={null}
                          showYearDropdown
                          yearDropdownItemNumber={80}
                          scrollableYearDropdown
                          maxDate={subYears(new Date(), 18)}
                          minDate={subYears(new Date(), 100)}
                          dayClassName={(date) => {
                            return getDate(date) === new Date().getDate() ? "text-sky-500" : "";
                          }}
                        />
                      )}
                    />
                    {/* <label
                      htmlFor="user.birthday"
                      className="bg-transparent form-label"
                    >
                      Doğum Tarihi
                    </label> */}
                    {formState.errors?.user?.birthday && (
                      <svg role="img" className="w-5 h-5 text-validation-error absolute top-1/4 right-3">
                        <use xlinkHref="/assets/img/necromancers.svg#error" />
                      </svg>
                    )}
                  </div>
                  <div
                    className={classNames("form-input-wrapper", {
                      error: formState?.errors?.user?.phoneNum,
                    })}
                  >
                    <Controller
                      className="bg-transparent form-input phone"
                      control={control}
                      rules={{ required: true }}
                      name="user.phoneNum"
                      render={({ field: { onChange, value } }) => (
                        <InputMask
                          {...register("user.phoneNum", { minLength: 16 })}
                          // innerRef={nodeRef}

                          name="phoneNum"
                          type="tel"
                          id="phoneNum"
                          className="bg-transparent form-input phone"
                          placeholder=" "
                          mask="0 (999) 999 99 99"
                          onChange={(e) => onChange(e)}
                          value={value || ""}
                          maskplaceholder="_"
                          data-testid="phone-input"
                        />
                      )}
                    />
                    <label htmlFor="phoneNum" className="bg-transparent form-label">
                      Cep Telefon Numaranız*
                    </label>
                    {formState.errors?.user?.phoneNum && (
                      <svg role="img" className="w-5 h-5 text-validation-error absolute top-1/4 right-3">
                        <use xlinkHref="/assets/img/necromancers.svg#error" />
                      </svg>
                    )}
                    {formState.errors?.user?.phoneNum && (
                      <p role="alert" className="mt-2 text-validation-error" id="email-error">
                        Zorunlu alan.
                      </p>
                    )}
                  </div>
                  <div
                    className={classNames("form-input-wrapper", {
                      error: formState?.errors?.user?.email,
                    })}
                  >
                    <input
                      {...register("user.email", { required: true })}
                      type="email"
                      id="email-address"
                      autoComplete="cc-given-email-address"
                      placeholder=" "
                      className="bg-transparent form-input"
                      data-testid="mail-input"
                    />
                    <label htmlFor="email-address" className="bg-transparent form-label">
                      Mail Adresiniz*
                    </label>
                    {formState.errors?.user?.email && (
                      <svg role="img" className="w-5 h-5 text-validation-error absolute top-1/4 right-3">
                        <use xlinkHref="/assets/img/necromancers.svg#error" />
                      </svg>
                    )}

                    {formState.errors?.user?.email && (
                      <p role="alert" className="mt-2 text-validation-error" id="email-error">
                        Zorunlu alan.
                      </p>
                    )}
                  </div>
                  {/* success input */}
                  {/* <div className="form-input-wrapper success">
                    <input
                      type="text"
                      id="Success"
                      autoComplete="cc-given-success"
                      name="Success"
                      placeholder=" "
                      className="bg-transparent form-input"
                    />
                    <label
                      htmlFor="Success"
                      className="bg-transparent form-label"
                    >
                      Success
                    </label>
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                      <svg
                        className="w-5 h-5 text-validation-confirmation"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="24" height="24" rx="12" fill="#1DC066" />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M17.4139 6.66669L18.6663 7.77603L9.92644 17.3334L5.33301 12.8125L6.5244 11.6399L9.86211 14.9248L17.4139 6.66669Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="flex-col justify-center flex-1 mt-16 lg:px-1 lg:mt-0">
                <h3 className="text-sm text-center lg:text-md lg:text-left">İş-Eğitim Bilgileri</h3>
                <div className="flex flex-col gap-8 mt-10">
                  <div
                    className={classNames("form-input-wrapper", {
                      error: formState?.errors?.user?.graduation,
                    })}
                  >
                    <select {...register("user.graduation")} id="graduation" autoComplete="graduation" className="bg-transparent form-input" data-testid="graduation-input">
                      {graduationOptions.map((grad) => (
                        <option key={grad.value} value={grad.value}>
                          {grad.label}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="graduation" className="bg-transparent form-label">
                      Eğitim Durumunuz
                    </label>
                  </div>
                  <div
                    className={classNames("form-input-wrapper", {
                      error: formState?.errors?.user?.dealerType,
                    })}
                  >
                    <select
                      value={dealerType}
                      onChange={(e) => {
                        setDealerType(e.target.value);
                        setValue("user.firm", "");
                        setBranches([]);
                      }}
                      id="dealerType"
                      name="dealerType"
                      autoComplete="dealerType"
                      className="bg-transparent form-input"
                    >
                      <option value="Bayi">Bayi</option>
                      <option value="Tali">Tali</option>
                    </select>
                    <label htmlFor="dealerType" className="bg-transparent form-label">
                      Bayi Tipi
                    </label>
                  </div>
                  <div
                    className={classNames("relative", {
                      error: formState?.errors?.user?.firm,
                    })}
                  >
                    <Controller
                      className="bg-transparent form-input firm"
                      control={control}
                      // rules={{ required: true }}
                      name="user.firm"
                      render={({ field: { onChange, value } }) => (
                        <CustomSelect
                          {...register("user.firm", { required: true })}
                          onChange={(e) => {
                            onChange(e.value);
                          }}
                          options={firms.map((firm) => ({
                            label: firm.Name,
                            value: firm.Id,
                          }))}
                          placeholder="Çalıştığınız Firma"
                          name="user.firm"
                          data-testid="firm-input"
                          value={
                            firms.find((firm) => firm.Id === value)?.Id
                              ? {
                                  label: firms.find((firm) => firm.Id === value)?.Name,
                                  value: value,
                                }
                              : null
                          }
                        />
                      )}
                    />
                    {formState.errors?.user?.firm && (
                      <svg role="img" className="w-5 h-5 text-validation-error absolute top-1/4 right-3">
                        <use xlinkHref="/assets/img/necromancers.svg#error" />
                      </svg>
                    )}

                    {formState.errors?.user?.firm && (
                      <p role="alert" className="mt-2 text-validation-error" id="email-error">
                        Zorunlu alan.
                      </p>
                    )}
                  </div>
                  <div
                    className={classNames("form-input-wrapper", {
                      error: formState?.errors?.user?.branch,
                    })}
                  >
                    <select {...register("user.branch")} id="location" autoComplete="location" className="bg-transparent form-input" data-testid="branch-input">
                      <option selected disabled></option>
                      {branches?.map((branch) => (
                        <option key={branch.Id} value={branch.Id}>
                          {branch.Name}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="location" className="bg-transparent form-label">
                      Merkez - Şube
                    </label>
                    {formState.errors?.user?.branch && (
                      <svg role="img" className="w-5 h-5 text-validation-error absolute top-1/4 right-3">
                        <use xlinkHref="/assets/img/necromancers.svg#error" />
                      </svg>
                    )}

                    {formState.errors?.user?.branch && (
                      <p role="alert" className="mt-2 text-validation-error" id="email-error">
                        Zorunlu alan.
                      </p>
                    )}
                  </div>
                  <div
                    className={classNames("form-input-wrapper", {
                      hidden: dealerType === "Tali",
                    })}
                  >
                    <div
                      className={classNames("form-input-wrapper", {
                        error: formState?.errors?.user?.placier,
                      })}
                    >
                      <input {...register("user.placier")} type="text" id="user.placier" className="bg-transparent form-input" placeholder=" " />
                      <label htmlFor="user.placier" className="form-label text-body-s text-secondary-titles">
                        Plasiyer Kodu
                      </label>
                      {formState.errors?.user?.placier && (
                        <svg role="img" className="w-5 h-5 text-validation-error absolute top-1/4 right-3">
                          <use xlinkHref="/assets/img/necromancers.svg#error" />
                        </svg>
                      )}
                      {formState.errors?.user?.placier && (
                        <p className="mt-2 text-validation-error" id="email-error">
                          Zorunlu alan.
                        </p>
                      )}
                    </div>
                  </div>
                  <div
                    className={classNames("relative", {
                      error: formState?.errors?.user?.title,
                    })}
                  >
                    <Controller
                      data-testid="title-input"
                      className="bg-transparent form-input title"
                      control={control}
                      rules={{ required: true }}
                      name="user.title"
                      render={({ field: { onChange, value } }) => (
                        <CustomSelect
                          error={formState?.errors?.user?.title}
                          {...register("user.title")}
                          onChange={(e) => {
                            onChange(e.value);
                          }}
                          options={titles.map((title) => ({
                            label: title.tr,
                            value: title.title,
                          }))}
                          placeholder="Ünvan"
                          name="user.title"
                        />
                      )}
                    />
                    {formState.errors?.user?.title && (
                      <svg role="img" className="w-5 h-5 text-validation-error absolute top-1/4 right-3">
                        <use xlinkHref="/assets/img/necromancers.svg#error" />
                      </svg>
                    )}

                    {formState.errors?.user?.title && (
                      <p role="alert" className="mt-2 text-validation-error" id="email-error">
                        Zorunlu alan.
                      </p>
                    )}
                  </div>
                  <div
                    className={classNames("form-input-wrapper", {
                      error: formState?.errors?.user?.city,
                    })}
                  >
                    <select {...register("user.city")} id="state" autoComplete="state" className="bg-transparent form-input" data-testid="city-input">
                      {cities.map((city) => (
                        <option key={city.ID} value={city.ID}>
                          {city.NAME}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="state" className="bg-transparent form-label">
                      İkamet İl
                    </label>
                  </div>
                  <div
                    className={classNames("form-input-wrapper", {
                      error: formState?.errors?.user?.region,
                    })}
                  >
                    <select {...register("user.region")} id="region" autoComplete="region" className="bg-transparent form-input" data-testid="region-input">
                      {regions.map((region) => (
                        <option key={region.ID} value={region.ID}>
                          {region.NAME}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="region" className="bg-transparent form-label">
                      İkamet İlçe
                    </label>
                    {formState.errors?.user?.region && (
                      <svg role="img" className="w-5 h-5 text-validation-error absolute top-1/4 right-3">
                        <use xlinkHref="/assets/img/necromancers.svg#error" />
                      </svg>
                    )}
                    {formState.errors?.user?.region && (
                      <p className="mt-2 text-validation-error" id="email-error">
                        Zorunlu alan.
                      </p>
                    )}
                  </div>
                  {/* fail input */}
                  {/* <div className="form-input-wrapper error">
                    <input
                      type="text"
                      id="Fail"
                      autoComplete="cc-given-Fail"
                      name="Fail"
                      placeholder=" "
                      className="bg-transparent form-input"
                      aria-invalid="true"
                      aria-describedby="email-error"
                    />
                    <label htmlFor="Fail" className="bg-transparent form-label">
                      Fail*
                    </label>
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                      <svg
                        className="w-5 h-5 text-validation-error"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="24" height="24" rx="12" fill="#FF7575" />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M17.8336 7.16693L13.2963 11.7042L17.8336 16.2415L16.5373 17.5378L12 13.0005L7.46271 17.5378L6.16639 16.2415L10.7037 11.7042L6.16639 7.16693L7.46271 5.8706L12 10.4079L16.5373 5.8706L17.8336 7.16693Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center px-4 py-3 pt-2 mx-auto mt-16 lg:max-w-md lg:space-y-12">
              <div className="space-y-6">
                <div className="form-input-wrapper">
                  <input {...register("user.kkEmailPermission")} type="checkbox" id="user.kkEmailPermission" className="mr-2 form-input" />
                  <label htmlFor="user.kkEmailPermission" className="form-label text-body-s text-secondary-titles">
                    <b
                      onClick={() => {
                        setKvkkActiveTab(1);
                        setKvkkModalOpen(true);
                        document.documentElement.scrollTo(0, 0);
                      }}
                      className="cursor-pointer"
                    >
                      Kalekim
                    </b>{" "}
                    Ticari elektronik ileti almak için onay veriyorum.
                  </label>
                </div>
                <div className="form-input-wrapper">
                  <input {...register("user.kkKvkkPermission", { required: true })} type="checkbox" id="user.kkKvkkPermission" className="mr-2 form-input" />
                  <label
                    htmlFor="user.kkKvkkPermission"
                    className={classNames("cursor-pointer form-label text-body-s ", {
                      "text-validation-error": formState?.errors?.user?.kkKvkkPermission,
                      "text-secondary-titles": !formState?.errors?.user?.kkKvkkPermission,
                    })}
                  >
                    <b
                      className="cursor-pointer"
                      onClick={() => {
                        setKvkkActiveTab(1);
                        setKvkkModalOpen(true);
                        document.documentElement.scrollTo(0, 0);
                      }}
                    >
                      Kalekim
                    </b>{" "}
                    Kişisel Verilere İlişkin Aydınlatma Metni'ni okudum, aydınlatma metni ve yürürlükteki mevzuat çerçevesinde kişisel verilerimin işlenmesini kabul ediyorum.
                  </label>
                  {formState.errors?.user?.kkKvkkPermission && (
                    <p className="mt-2 text-validation-error" id="email-error" role="alert">
                      Zorunlu alan.
                    </p>
                  )}
                </div>
              </div>
              <div
                className={classNames("space-y-6", {
                  hidden: dealerType === "Tali", // Tali ise alanları gizle
                })}
              >
                <div className="form-input-wrapper">
                  <input {...register("user.ksEmailPermission")} type="checkbox" id="user.ksEmailPermission" className="mr-2 form-input" />
                  <label htmlFor="user.ksEmailPermission" className="form-label text-body-s text-secondary-titles">
                    <b
                      className="cursor-pointer"
                      onClick={() => {
                        setKvkkActiveTab(2);
                        setKvkkModalOpen(true);
                        document.documentElement.scrollTo(0, 0);
                      }}
                    >
                      Kaleseramik
                    </b>{" "}
                    Ticari elektronik ileti almak için onay veriyorum.
                  </label>
                </div>
                <div className="form-input-wrapper">
                  <input
                    {...register("user.ksKvkkPermission", {
                      required: dealerType !== "Tali",
                    })}
                    type="checkbox"
                    id="user.ksKvkkPermission"
                    className="mr-2 form-input"
                  />
                  <label
                    htmlFor="user.ksKvkkPermission"
                    className={classNames("cursor-pointer form-label text-body-s ", {
                      "text-validation-error": formState?.errors?.user?.ksKvkkPermission,
                      "text-secondary-titles": !formState?.errors?.user?.ksKvkkPermission,
                    })}
                  >
                    <b
                      onClick={() => {
                        setKvkkActiveTab(2);
                        setKvkkModalOpen(true);
                        document.documentElement.scrollTo(0, 0);
                      }}
                    >
                      Kaleseramik
                    </b>{" "}
                    Kişisel Verilere İlişkin Aydınlatma Metni'ni okudum, aydınlatma metni ve yürürlükteki mevzuat çerçevesinde kişisel verilerimin işlenmesini kabul ediyorum.
                  </label>
                  {formState.errors?.user?.ksKvkkPermission && (
                    <p className="mt-2 text-validation-error" id="email-error" role="alert">
                      Zorunlu alan.
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="flex items-center w-full px-4 mx-auto mt-16 lg:max-w-xs sm:px-6">
              <button data-testid="register-button" type="submit" className="w-full max-w-full uppercase form-submit-btn">
                Üye Ol
              </button>
            </div>
          </form>
        </main>
      </div>

      <div className="py-16 bg-background-teal">
        <main className="mx-auto">
          <div className="flex items-center justify-center px-1 mx-auto max-w-7xl">
            <div className="space-y-8 text-center">
              <div className="space-y-2">
                <h2 className="text-md">Üye Girişi</h2>
                <p className="max-w-xl text-secondary-primary">Mevcut hesabınız mı var?</p>
              </div>
              <Link to="/" className="btn">
                ŞİMDİ GİRİŞ YAP
              </Link>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default RegisterPage;
