import React, { useEffect, useState, memo } from "react";
import DonutChart from "react-donut-chart";

function ActivePoints({ pointData, siteId }) {
  const [pieData, setPieData] = useState([]);

  const formatNumber = (number) => {
    const num = Number(number);
    if (isNaN(num)) return "0";

    return num.toLocaleString("tr-TR", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
    });
  };

  useEffect(() => {
    if (!pointData) return;

    setPieData(
      siteId === "tali"
        ? [
            {
              label: `Kale Puan`,
              value: Number(pointData?.remainingKSPoint) < 0 ? 0 : Number(pointData?.remainingKSPoint),
            },
          ]
        : [
            {
              label: `Kaleseramik Puanı`,
              value: Number(pointData?.remainingKSPoint) < 0 ? 0 : Number(pointData?.remainingKSPoint),
            },
            {
              label: `Kalekim Puanı`,
              value: Number(pointData?.remainingKKPoint) < 0 ? 0 : Number(pointData?.remainingKKPoint),
            },
          ]
    );
  }, [pointData, siteId]);

  return (
    <div>
      <div className="flex flex-col px-4 lg:px-0">
        <div className="flex flex-col gap-12 lg:flex-row">
          <div className="flex flex-col w-full max-w-4xl mx-auto mb-16 space-y-4 md:flex-row cdc-container justify-evenly">
            <div className="flex flex-row space-x-24">
              <div className="flex flex-col relative">
                <div className="cdc-overlay z-50 w-[325px] h-[325px]">
                  <div className="cdc-text">
                    <div className="space-y-4">
                      <p className="text-body-s font-sm">Toplam</p>
                      <p className="text-lg font-lg">{formatNumber(pointData?.total_point)}</p>
                      <p className="text-body-s text-secondary-secondary">Kale Puan</p>
                    </div>
                  </div>
                </div>
                <DonutChart
                  data={pieData}
                  interactive={false}
                  selectedOffset={false}
                  colors={siteId === "tali" ? ["#6258C9"] : ["#E94034", "#6258C9"]}
                  legend={false}
                  width={325}
                  height={325}
                  strokeColor="transparent"
                  className="p-0"
                />
                {/* <p className="mt-8 text-body-s text-secondary-secondary">
                  Son Kullanım Tarihi; 01.12.2021
                </p> */}
              </div>

              <div className="flex flex-col justify-start ml-4 space-y-4 cdc-legend pt-6 w-96">
                {siteId !== "tali" && (
                  <span title="Kaleseramik Puan" className="cdc-legend-item">
                    <span className="rounded-full cdc-legend-item-color bg-primary-red"></span>
                    <span>
                      Kaleseramik Puan <b>{formatNumber(pointData?.remainingKSPoint)}</b>
                    </span>
                  </span>
                )}

                <span title={siteId === "tali" ? "Kale Puan" : "Kalekim Puan"} className="cdc-legend-item">
                  <span className="rounded-full cdc-legend-item-color bg-tertiary-purple"></span>
                  <span className="">
                    {siteId === "tali" ? "Kale Puan " : "Kalekim Puan "}
                    <b>{formatNumber(pointData?.remainingKKPoint)}</b>
                  </span>
                </span>

                <p className="text-secondary-secondary">{siteId !== "tali" && <span>KS; Kaleseramik | KK; Kalekim</span>}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default memo(ActivePoints);