import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LoadingModal from "../../../components/shared/LoadingModal";
import AuthLayout from "../../../layouts/AuthLayout";
import { getNews } from "../../../services/contentService";
import { Helmet } from "react-helmet";

export default function News() {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const news = await getNews();
        setNews(news);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const result = date.toLocaleDateString("tr-TR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    return result;
  };

  return (
    <>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-QFLY5D5WWK"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag("js", new Date());
            gtag("config", "G-QFLY5D5WWK");
          `}
        </script>
      </Helmet>
      <AuthLayout>
        {loading && <LoadingModal />}
        <div id="content" className="lg:p-0">
          <div className="py-5 bg-background-teal md:px-4 lg:py-16 xl:px-0">
            <main className="px-4 mx-auto max-w-7xl lg:px-1">
              <div className="flex flex-col justify-between grid-cols-12 p-4 space-y-4 lg:p-0 lg:grid lg:flex-row lg:items-center sm:space-y-0">
                <h1 className="col-span-7 text-md lg:text-xxl text-primary-black font-lg lg:mb-0">Kale’den Haberler</h1>
              </div>
            </main>
          </div>
          <div className="px-4 mx-auto my-12 max-w-7xl lg:my-16 lg:px-0">
            <div className="flex flex-col grid-cols-12 gap-6 px-4 lg:grid xl:p-0 ">
              {news?.map((newsItem) => {
                const item = newsItem.toJSON();
                return (
                  <div className="flex-col col-span-4 px-4 py-6 border border-secondary-lines lg:flex" key={item?.objectId}>
                    <div className="flex flex-col gap-4">
                      <Link to={`/news/${item?.objectId}`}>
                        <img
                          src={item?.thumbnail?.url}
                          style={{
                            width: 376,
                            height: 376,
                            objectFit: "cover",
                            objectPosition: "center",
                          }}
                          className="w-full"
                          alt={item?.title}
                        />
                      </Link>
                    </div>
                    <div className="pt-6">
                      <p className="mb-2 text-primary-red text-btn">{formatDate(item?.createdAt)}</p>
                      <p className="mb-2 text-xs font-xs">{item?.title}</p>
                      <p>{item?.text}</p>
                    </div>
                    <div className="flex justify-end">
                      <Link
                        to={`/news/${item?.objectId}`}
                        className="inline-flex items-center px-6 py-3 mt-6 btn btn-primary border-primary-red text-primary-red hover:text-white"
                      >
                        Detaylar
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
}
