import React, { useState, useEffect } from "react";
import { getCities, getRegions } from "../../../../services/authService";
import InputMask from "react-input-mask";
import { isEmpty } from "../../../../util/FormValidationRules";
import classNames from "classnames";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";
import { getDate, subYears, parseISO } from "date-fns";

export default function PersonalInformation({
  userData,
  setUserData,
  errors,
  setErrors,
  user,
}) {
  registerLocale("tr", tr);
  const [cities, setCities] = useState([]);
  const [regions, setRegions] = useState([]);

  const getCityName = (id) => {
    const city = cities?.find((city) => city.ID === id);
    return city.NAME;
  };

  const getRegionName = (id) => {
    const region = regions?.find((region) => region.ID === id);
    return region.NAME;
  };

  const sortByName = (a, b) => {
    return a.NAME.localeCompare(b.NAME);
  };

  useEffect(() => {
    (async () => {
      const regions = await getRegions(
        userData?.Billing_City__c || cities[0]?.ID
      );
      regions.sort((a, b) => sortByName(a, b));
      setRegions(regions);
      if (user.Billing_City__c === userData.Billing_City__c) return;
      setUserData({
        ...userData,
        Billing_Region__c: regions[0].ID,
        Billing_Region__r: regions[0].NAME,
      });
    })();
    //eslint-disable-next-line
  }, [userData?.Billing_City__r, cities]);

  useEffect(() => {
    (async () => {
      const cities = await getCities();
      cities.sort((a, b) => sortByName(a, b));
      setCities(cities);
    })();
  }, []);

  return (
    <div className="prose-xxl">
      <div className="max-w-sm mx-auto">
        <div
          className={classNames("mb-8 form-input-wrapper", {
            error: errors?.cityEmpty,
          })}
        >
          <select
            onBlur={(e) =>
              setErrors({
                ...errors,
                cityEmpty: isEmpty(e.target.value),
              })
            }
            id="graduation"
            name="graduation"
            autoComplete="graduation"
            className="bg-transparent form-input"
            value={userData?.Billing_City__c}
            onChange={(e) => {
              setUserData({
                ...userData,
                Billing_City__c: e.target.value,
                Billing_City__r: getCityName(e.target.value),
                City__r: getCityName(e.target.value),
              });
            }}
          >
            {cities?.map((city) => (
              <option key={city.ID} value={city?.ID}>
                {city?.NAME}
              </option>
            ))}
          </select>
          <label htmlFor="graduation" className="bg-transparent form-label">
            Şehir
          </label>
          {errors?.cityEmpty && (
            <svg
              role="img"
              className="w-5 h-5 text-validation-error absolute top-1/4 right-3"
            >
              <use xlinkHref="/assets/img/necromancers.svg#error" />
            </svg>
          )}
          {errors?.cityEmpty && (
            <p
              role="alert"
              className="mt-2 text-validation-error"
              id="email-error"
            >
              Zorunlu alan.
            </p>
          )}
        </div>

        <div
          // className={classNames("mb-8 form-input-wrapper", {
          //   error: errors?.regionEmpty,
          // })}
          className="mb-8 form-input-wrapper"
        >
          <select
            id="occupation"
            name="occupation"
            autoComplete="occupation"
            className="bg-transparent form-input"
            value={userData?.Billing_Region__c}
            onChange={(e) => {
              setUserData({
                ...userData,
                Billing_Region__c: e.target.value,
                Billing_Region__r: getRegionName(e.target.value),
              });
            }}
            onBlur={(e) =>
              setErrors({
                ...errors,
                regionEmpty: isEmpty(e.target.value),
              })
            }
          >
            {regions?.map((region) => (
              <option key={region.ID} value={region?.ID}>
                {region?.NAME}
              </option>
            ))}
          </select>
          <label htmlFor="occupation" className="bg-transparent form-label">
            İlçe
          </label>
          {/* {errors?.regionEmpty && <svg
              role="img"
              className="w-5 h-5 text-validation-error absolute top-1/4 right-3"
            >
              <use xlinkHref="/assets/img/necromancers.svg#error" />
            </svg>}
          {errors?.regionEmpty && (
            <p
              role="alert"
              className="mt-2 text-validation-error"
              id="email-error"
            >
              Zorunlu alan.
            </p>
          )} */}
        </div>

        <div
          className="mb-8 form-input-wrapper z-50 relative"
        >
          <label
            htmlFor="birthdate"
            className={classNames(
              "absolute left-[13px] text-[12px] text-[#76768F] transition-all",
              {
                "top-[2px] text-[12px] text-[#76768F]": userData?.birthdate, // Tarih seçildiyse label yukarıda
                "top-[50%] -translate-y-1/2 text-[16px] text-[#76768F]": !userData?.birthdate, // Tarih seçili değilse label ortada
              }
            )}
          >
            Doğum Tarihi
          </label>
          <DatePicker
            className="bg-transparent datepicker form-input-date border-gray-300 focus:border-gray-600"
            dateFormat="dd/MM/yyyy"
            selected={
              userData?.birthdate ? parseISO(userData?.birthdate) : null
            }
            onChange={(date) => {
              setUserData({
                ...userData,
                birthdate: new Date(date.setHours(12)).toISOString(),
              });
            }}
            locale={tr}
            todayButton={null}
            showYearDropdown
            yearDropdownItemNumber={80}
            scrollableYearDropdown
            maxDate={subYears(new Date(), 18)}
            minDate={subYears(new Date(), 100)}
            dayClassName={(date) => {
              return getDate(date) === new Date().getDate()
                ? "text-sky-500"
                : "";
            }}
          />
        </div>

        <div
          className={classNames("mb-8 form-input-wrapper", {
            error: errors?.zipEmpty,
          })}
        >
          <InputMask
            name="post-code"
            type="text"
            id="zip"
            autoComplete="cc-given-zip"
            className="bg-transparent form-input phone"
            placeholder=" "
            mask="99999"
            value={userData?.zipCode}
            onChange={(e) =>
              setUserData({ ...userData, zipCode: e.target.value })
            }
            onBlur={(e) =>
              setErrors({
                ...errors,
                zipEmpty: isEmpty(e.target.value),
              })
            }
            maskplaceholder="_____"
          />
          <label htmlFor="phone" className="bg-transparent form-label">
            Posta Kodu
          </label>
          {errors?.zipEmpty && (
            <svg
              role="img"
              className="w-5 h-5 text-validation-error absolute top-1/4 right-3"
            >
              <use xlinkHref="/assets/img/necromancers.svg#error" />
            </svg>
          )}
          {errors?.zipEmpty && (
            <p
              role="alert"
              className="mt-2 text-validation-error"
              id="email-error"
            >
              Zorunlu alan.
            </p>
          )}
        </div>

        <div
          className={classNames("mb-8 form-input-wrapper", {
            error: errors?.addressEmpty,
          })}
        >
          <textarea
            type="text"
            id="address"
            autoComplete="cc-given-address"
            name="address"
            placeholder=" "
            value={userData?.BillingStreet}
            onBlur={(e) =>
              setErrors({
                ...errors,
                addressEmpty: isEmpty(e.target.value),
              })
            }
            onChange={(e) =>
              setUserData({
                ...userData,
                address: e.target.value,
                BillingStreet: e.target.value,
              })
            }
            className="bg-transparent resize-none form-input h-36"
            style={{
              borderColor:
                errors.addressEmpty &&
                "rgb(255 117 117 / var(--tw-border-opacity))",
            }}
          ></textarea>
          <label htmlFor="address" className="bg-transparent form-label">
            Adres
          </label>
          {errors?.addressEmpty && (
            <svg
              role="img"
              className="w-5 h-5 text-validation-error absolute top-1/4 right-3"
            >
              <use xlinkHref="/assets/img/necromancers.svg#error" />
            </svg>
          )}
          {errors?.addressEmpty && (
            <p
              role="alert"
              className="mt-2 text-validation-error"
              id="email-error"
            >
              Zorunlu alan.
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
