import React, { useState, useEffect } from "react";
import CustomSelect from "../../../../components/shared/CustomSelect";
import { getBayiTitles } from "../../../../services/authService";
import { isEmpty } from "../../../../util/FormValidationRules";
import classNames from "classnames";

export default function WorkAndStudyInformation({ userData, setUserData, errors, setErrors, firms, getFirmName, branches, getBranchType }) {
  const [titles, setTitles] = useState(null);


  useEffect(() => {
  
    (async () => {
      const titles = await getBayiTitles();
      setTitles(titles);
    })();
  }, []);

  useEffect(() => {
    if(userData && userData.branch && userData.branch.trim()!=='') setUserData({...userData, The_Kale_Company_Work_With__c: getBranchType(userData?.branch)})
    //eslint-disable-next-line
  },[userData?.branch])

  const getTurkishTitle = () => {
    const currentTitle = titles?.find(
      (title) => title?.title === userData?.Title_of_Person__c
    );
    return currentTitle?.tr;
  };

  const graduationOptions = [
    {
      active: true,
      defaultValue: false,
      label: "İlkokul",
      validFor: null,
      value: "Primary School Degree",
    },
    {
      active: true,
      defaultValue: false,
      label: "Orta Okul",
      validFor: null,
      value: "Middle School",
    },
    {
      active: true,
      defaultValue: false,
      label: "Lise",
      validFor: null,
      value: "High School Degree",
    },
    {
      active: true,
      defaultValue: false,
      label: "Teknik Lise",
      validFor: null,
      value: "Technical Highschool Degree",
    },
    {
      active: true,
      defaultValue: false,
      label: "Önlisans",
      validFor: null,
      value: "College Degree",
    },
    {
      active: true,
      defaultValue: false,
      label: "Lisans",
      validFor: null,
      value: "Bachelor Degree",
    },
    {
      active: true,
      defaultValue: false,
      label: "Yüksek Lisans",
      validFor: null,
      value: "Master Degree",
    },
    {
      active: true,
      defaultValue: false,
      label: "Doktora",
      validFor: null,
      value: "Doctorate",
    },
    {
      active: true,
      defaultValue: false,
      label: "Diğer",
      validFor: null,
      value: "Other",
    },
  ];

  return (
    <div className="prose-xxl">
      <div className="max-w-sm mx-auto">
        <div className="mb-8">
          <div
            className={classNames("form-input-wrapper", {
              hidden: userData?.siteId === "tali",
              error: errors?.placierEmpty,
            })}
          >
            <input
              type="text"
              id="dob"
              name="work-start-date"
              placeholder=" "
              value={userData?.placier}
              onChange={(e) =>
                setUserData({ ...userData, placier: e.target.value })
              }
              onBlur={(e) =>
                setErrors({ ...errors, placierEmpty: isEmpty(e.target.value) })
              }
              className="bg-transparent form-input datepicker"
            />
            <label htmlFor="dob" className="bg-transparent form-label">
              Plasiyer kodu
            </label>
            {errors?.placierEmpty && <svg
              role="img"
              className="w-5 h-5 text-validation-error absolute top-1/4 right-3"
            >
              <use xlinkHref="/assets/img/necromancers.svg#error" />
            </svg>}
            {errors?.placierEmpty && (
              <p
                role="alert"
                className="mt-2 text-validation-error"
                id="email-error"
              >
                Zorunlu alan.
              </p>
            )}
          </div>
        </div>

        <div
          className={classNames("mb-8", {
            error: errors?.titleEmpty,
          })}
        >
          <CustomSelect
            value={{
              label: getTurkishTitle(),
              value: userData?.Title_of_Person__c,
            }}
            placeholder="Unvan"
            options={titles?.map((title) => ({
              label: title?.tr,
              value: title?.title,
            }))}
            onChange={(e) =>
              setUserData({ ...userData, 
                Title_of_Person__c: e.value,
                Class__c: e.value
               })
            }
            onBlur={(e) =>
              setErrors({ ...errors, titleEmpty: isEmpty(e.value) })
            }
          />
          {errors?.titleEmpty && <svg
              role="img"
              className="w-5 h-5 text-validation-error absolute top-1/4 right-3"
            >
              <use xlinkHref="/assets/img/necromancers.svg#error" />
            </svg>}
          {errors?.titleEmpty && (
            <p
              role="alert"
              className="mt-2 text-validation-error"
              id="email-error"
            >
              Zorunlu alan.
            </p>
          )}
        </div>

        <div
          className={classNames("mb-8  form-input-wrapper", {
            error: errors?.educationEmpty,
          })}
        >
          <select
            id="graduation"
            name="graduation"
            autoComplete="graduation"
            value={userData?.Education_Status__c}
            onChange={(e) =>
              setUserData({ ...userData, Education_Status__c: e.target.value })
            }
            onBlur={(e) =>
              setErrors({ ...errors, educationEmpty: isEmpty(e.target.value) })
            }
            className="bg-transparent form-input"
          >
            {graduationOptions.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          <label htmlFor="graduation" className="bg-transparent form-label">
            Eğitim Durumunuz
          </label>
          {errors?.educationEmpty && <svg
              role="img"
              className="w-5 h-5 text-validation-error absolute top-1/4 right-3"
            >
              <use xlinkHref="/assets/img/necromancers.svg#error" />
            </svg>}
          {errors?.educationEmpty && (
            <p
              role="alert"
              className="mt-2 text-validation-error"
              id="email-error"
            >
              Zorunlu alan.
            </p>
          )}
        </div>

        <div
          className={classNames("mb-8", {
            error: errors?.firmEmpty,
          })}
        >
          <CustomSelect
            value={{ label: getFirmName(userData?.firm), value: userData?.firm }}
            placeholder="Çalıştığınız Firma"
            options={firms?.map((firm) => ({
              label: firm.Name,
              value: firm.Id,
            }))}
            onChange={(e) => {
              setUserData({ ...userData, firm: e.value, branch:"" })
            }}
            onBlur={(e) =>
              setErrors({ ...errors, firmEmpty: isEmpty(e.value) })
            }
          />
          {errors?.firmEmpty && <svg
              role="img"
              className="w-5 h-5 text-validation-error absolute top-1/4 right-3"
            >
              <use xlinkHref="/assets/img/necromancers.svg#error" />
            </svg>}
          {errors?.firmEmpty && (
            <p
              role="alert"
              className="mt-2 text-validation-error"
              id="email-error"
            >
              Zorunlu alan.
            </p>
          )}
        </div>

        <div
          className={classNames("mb-8 form-input-wrapper", {
            // error: errors?.branchEmpty,
          })}
        >
          <select
            id="location"
            name="location"
            autoComplete="location"
            className="bg-transparent form-input"
            value={userData?.branch}
            onChange={(e) =>
              setUserData({ ...userData, branch: e.target.value })
            }
            onBlur={(e) =>
              setErrors({ ...errors, branchEmpty: isEmpty(e.target.value) })
            }
          >
            <option></option>
            {branches?.map((item) => (
              <option key={item.Id} value={item.Id}>
                {item.Name}
              </option>
            ))}
          </select>
          <label htmlFor="location" className="bg-transparent form-label">
            Merkez - Şube
          </label>
          {/* {errors?.branchEmpty && <svg
              role="img"
              className="w-5 h-5 text-validation-error absolute top-1/4 right-3"
            >
              <use xlinkHref="/assets/img/necromancers.svg#error" />
            </svg>}
          {errors?.branchEmpty && (
            <p
              role="alert"
              className="mt-2 text-validation-error"
              id="email-error"
            >
              Zorunlu alan.
            </p>
          )} */}
        </div>
      </div>
    </div>
  );
}
