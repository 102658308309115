import React, { useEffect, useState } from "react";
import AuthLayout from "../../layouts/AuthLayout";
import { getKaleWallContent, getLatestAlbumFromKaleGallery, getLatestOtherDoc, getPosts, getUserPointsRanking } from "../../services/contentService";
import Post from "./components/Post";
import Lightbox from "react-image-lightbox";
import { Link, useLocation, useNavigate } from "react-router-dom";

import CreatePost from "./components/CreatePost";
import CreatePostMobile from "./components/CreatePostMobile";
import LoadingModal from "../../components/shared/LoadingModal";
import { Helmet } from "react-helmet";
import Parse from "parse";

export default function KaleWall() {
  const [svgType, setSvgType] = useState("pdf");
  const [posts, setPosts] = useState([]);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [latestDoc, setLatestDoc] = useState(null);
  const [latestAlbum, setLatestAlbum] = useState(null);
  const [content, setContent] = useState(null);
  const [pointRanking, setPointRanking] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    handleGetPosts();
    getLatestDoc();
    getLatestAlbum();
    handleGetKaleWallContent();
    handleGetPointRanking();
  }, []);

  useEffect(() => {
    const user = Parse.User.current();
    if (user) {
      setCurrentUser(user.toJSON());
    }
  }, []);

  const getLatestAlbum = async () => {
    let album = await getLatestAlbumFromKaleGallery();
    const { images } = album;
    const reversedImages = images?.reverse();
    album = {
      ...album,
      images: reversedImages,
    };
    setLatestAlbum(album);
  };

  const getLatestDoc = async () => {
    setLoading(true);
    const doc = await getLatestOtherDoc();
    setLatestDoc(doc?.toJSON());
    setLoading(false);
  };

  const handleGetKaleWallContent = async () => {
    setLoading(true);
    const content = await getKaleWallContent();
    setContent(content);
    setLoading(false);
  };

  const handleGetPointRanking = async () => {
    setLoading(true);
    const pointRanking = await getUserPointsRanking();
    setPointRanking(pointRanking);
    setLoading(false);
  };

  const handleGetPosts = async () => {
    setLoading(true);
    const posts = await getPosts();
    setPosts(posts);
    setLoading(false);
  };

  useEffect(() => {
    switch (latestDoc?.documentType) {
      case "pdf":
        setSvgType("pdf");
        break;
      case "doc":
        setSvgType("document");
        break;
      case "docx":
        setSvgType("document");
        break;
      case "pptx":
        setSvgType("powerpoint");
        break;
      case "xls":
        setSvgType("excel");
        break;
      default:
        setSvgType("pdf");
    }
  }, [latestDoc?.documentType]);

  return (
    <>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-QFLY5D5WWK"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag("js", new Date());
            gtag("config", "G-QFLY5D5WWK");
          `}
        </script>
      </Helmet>
      <AuthLayout>
        {loading && <LoadingModal />}
        <div id="content" className="p-4 lg:p-0">
          <div className="py-5 bg-background-teal md:px-4 lg:py-16 lg:px-0">
            <main className="px-4 mx-auto max-w-7xl lg:px-1">
              <div className="flex flex-col justify-between grid-cols-12 p-4 space-y-4 lg:p-0 lg:grid lg:flex-row lg:items-center sm:space-y-0">
                <h1 className="col-span-7 text-md lg:text-xxl text-primary-black font-lg lg:mb-0">{content?.title}</h1>
                <h6 className="col-span-5">{content?.text}</h6>
              </div>
            </main>
          </div>
          <CreatePostMobile setPosts={(posts) => setPosts(posts)} />

          <div className="px-4 mx-auto my-12 lg:px-1 max-w-7xl lg:my-16">
            <div className="flex flex-col-reverse grid-cols-12 gap-6 lg:grid">
              <div className="flex flex-col col-span-6 gap-6">
                <CreatePost setPosts={(posts) => setPosts(posts)} />
                {posts?.map((post) => (
                  <Post post={post} key={post?.id} posts={posts} setPosts={(posts) => setPosts(posts)} />
                ))}
              </div>
              <div className="flex flex-col col-span-6 gap-6">
                <div className="flex flex-col p-6 border border-secondary-lines">
                  <div className="flex items-center justify-between w-full">
                    <p className="text-sm text-secondary-titles font-md">Son Fotoğraf</p>
                    <Link to={`/gallery/${latestAlbum?.objectId}`} className="w-auto max-w-full uppercase form-submit-btn revert">
                      TÜMÜNÜ GÖR
                    </Link>
                  </div>

                  {isLightboxOpen && (
                    <Lightbox
                      mainSrc={latestAlbum?.images?.[photoIndex]?.url}
                      nextSrc={latestAlbum?.images?.[(photoIndex + 1) % latestAlbum?.images?.length]?.url}
                      prevSrc={latestAlbum?.images?.[(photoIndex + latestAlbum?.images?.length - 1) % latestAlbum?.images?.length]?.url}
                      onCloseRequest={() => setIsLightboxOpen(false)}
                      onMovePrevRequest={() => setPhotoIndex((photoIndex + latestAlbum?.images?.length - 1) % latestAlbum?.images?.length)}
                      onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % latestAlbum?.images?.length)}
                    />
                  )}

                  <div className="pt-6">
                    <img
                      src={latestAlbum?.images[0]?.url}
                      className="w-full cursor-pointer"
                      alt="kale-img-0"
                      onClick={() => {
                        setPhotoIndex(0);
                        setIsLightboxOpen(true);
                      }}
                    />
                  </div>
                  <div className="flex gap-4 pt-6 flex-wrap">
                    {latestAlbum?.images?.map((img, index) => {
                      if (index !== 0) {
                        return (
                          <span className="w-24 h-24 cursor-pointer flex" key={index}>
                            <img
                              src={img?.url}
                              alt={`kale-img-${index}`}
                              className="w-full h-full object-cover object-center"
                              onClick={() => {
                                setPhotoIndex(index);
                                setIsLightboxOpen(true);
                              }}
                            />
                          </span>
                        );
                      } else {
                        return <span key={index} className="sr-only"></span>;
                      }
                    })}
                  </div>
                </div>
                {currentUser?.siteId !== "tali" && (
                  <div className="flex flex-col p-6 border border-secondary-lines">
                    <div className="flex items-center justify-between w-full">
                      <p className="text-sm text-secondary-titles font-md">Kale Puan Sıralamam</p>

                      <button
                        type="submit"
                        className="w-auto max-w-full uppercase form-submit-btn revert"
                        onClick={() =>
                          navigate("/points", {
                            state: { prevPath: location.pathname },
                          })
                        }
                      >
                        DETAYLI İNCELE
                      </button>
                    </div>
                    <div className="flex flex-col items-center gap-4 pt-6 lg:flex-row">
                      <div className="flex flex-col items-center justify-center rounded-full w-44 h-44 bg-tertiary-purple">
                        <h3 className="text-lg text-white">{pointRanking?.GeneralPlace}</h3>
                        <p className="px-8 text-center text-white text-body-l font-body">Genel Sıralamada</p>
                      </div>
                      <div className="flex flex-col items-center justify-center rounded-full w-44 h-44 bg-tertiary-blue">
                        <h3 className="text-lg text-white">{pointRanking?.CityPlace}</h3>
                        <p className="px-8 text-center text-white text-body-l font-body">Şehir Bazında Sıralamada</p>
                      </div>
                      <div className="flex flex-col items-center justify-center rounded-full w-44 h-44 bg-primary-green">
                        <h3 className="text-lg text-white">{pointRanking?.FirmPlace}</h3>
                        <p className="px-8 text-center text-white text-body-l font-body">Firma Bazında Sıralamada</p>
                      </div>
                    </div>
                  </div>
                )}
                <div className="flex flex-col p-6 border border-secondary-lines">
                  <div className="flex items-center justify-between w-full">
                    <p className="text-sm text-secondary-titles font-md">Son Doküman</p>
                    <Link to="/library" type="submit" className="w-auto max-w-full uppercase form-submit-btn revert">
                      Tümü
                    </Link>
                  </div>
                  <a href={latestDoc?.file?.url}>
                    <div className="flex items-center pt-6">
                      <svg role="img" className="w-32 h-32 mr-4 text-secondary-primary">
                        <use xlinkHref={`/assets/img/necromancers.svg#${svgType}`} />
                      </svg>
                      <p>{latestDoc?.title}</p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
}
