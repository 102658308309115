import React, { useState, memo, useEffect } from "react";
import Pagination from "./Pagination";
import DonutChart from "react-donut-chart";

function EarnedPoints({ pointData, siteId }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);

  const formatNumber = (number) => {
    const num = Number(number);
    if (isNaN(num)) return "0";

    return num.toLocaleString("tr-TR", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
    });
  };

  const filteredRecords = pointData?.records?.filter(
    (record) => record.kk_amount > 0 || record.ks_amount > 0
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems = filteredRecords?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const [pieData, setPieData] = useState([]);

  useEffect(() => {
    if (!pointData) return;
    setPieData([
      {
        label: `Kaleseramik Puan`,
        value: Number(pointData?.groups?.ksPoint),
      },
      {
        label: "Anketler",
        value: Number(
          Number(pointData?.groups?.survey?.kk) +
          Number(pointData?.groups?.survey?.ks)
        ),
      },
      {
        label: `Kalekim Puan`,
        value: Number(pointData?.groups?.kkPoint),
      },
      {
        label: "Video İzleme",
        value: Number(
          Number(pointData?.groups?.liveBroadcast?.kk) +
          Number(pointData?.groups?.liveBroadcast?.ks)
        ),
      },
      {
        label: "Yarışma",
        value: Number(
          Number(pointData?.groups?.exam?.kk) +
          Number(pointData?.groups?.exam?.ks)
        ),
      },

      {
        label: "Online Olma ve Site Deneyimi",
        value: Number(
          Number(pointData?.groups?.beingOnline?.kk) +
          Number(pointData?.groups?.beingOnline?.ks)
        ),
      },
      {
        label: "Manuel Puan Müdahelesi",
        value: Number(
          Number(pointData?.groups?.manuel?.kk) +
          Number(pointData?.groups?.manuel?.ks)
        ),
      },
    ]);
  }, [pointData]);

  return (
    <div>
      <div className="flex flex-col px-4 lg:px-0">
        <div className="flex flex-col gap-12 lg:flex-row">
          <div className="flex flex-col w-full max-w-4xl mx-auto mb-16 space-y-4 md:flex-row cdc-container justify-evenly">
            <div className="flex flex-row space-x-24">
              <div className="flex flex-col relative">
                <div className="cdc-overlay z-50 w-[325px] h-[325px]">
                  <div className="cdc-text">
                    <div className="space-y-4">
                      <p className="text-body-s font-sm">Toplam</p>
                      <p className="text-lg font-lg">
                        {formatNumber(
                          Number(pointData?.groups?.ksPoint) +
                          Number(pointData?.groups?.kkPoint)
                        )}
                      </p>
                      <p className="text-body-s text-secondary-secondary">
                        Kale Puan
                      </p>
                    </div>
                  </div>
                </div>
                <DonutChart
                  data={pieData}
                  interactive={false}
                  // selectedOffset={false}
                  colors={
                    siteId === "tali"
                      ? [
                        "#E94034",
                        "#39ADF7",
                        "#6258C9",
                        "#be14d9",
                        "#57AF6A",
                        "#CDDB5F",
                        "#818285",
                      ]
                      : [
                        "#39ADF7",
                        "#6258C9",
                        "#be14d9",
                        "#57AF6A",
                        "#CDDB5F",
                        "#818285",
                      ]
                  }
                  legend={false}
                  width={325}
                  height={325}
                  strokeColor="transparent"
                  className="p-0"
                />
                {/* <p className="mt-8 text-body-s text-secondary-secondary">
                  Son Kullanım Tarihi; 01.12.2021
                </p> */}
              </div>

              <div className="flex flex-col justify-start ml-4 space-y-4 cdc-legend pt-6 w-96">
                {siteId !== "tali" && (
                  <span title="24 (24%)" className="cdc-legend-item">
                    <span className="rounded-full cdc-legend-item-color bg-primary-red"></span>
                    <span>
                      Kaleseramik Puan{" "}
                      <b>
                        {formatNumber(pointData?.groups?.ksPoint)}
                      </b>
                    </span>
                  </span>
                )}

                <span title="24 (24%)" className="cdc-legend-item">
                  <span className="rounded-full cdc-legend-item-color bg-tertiary-blue"></span>
                  <span>
                    Anketler{" "}
                    <b>
                      {formatNumber(
                        pointData?.groups?.survey?.ks +
                        pointData?.groups?.survey?.kk
                      )}
                    </b>
                    {siteId !== "tali" &&
                      `(${`KS ${formatNumber(pointData?.groups?.survey?.ks)}, 
                        KK ${formatNumber(pointData?.groups?.survey?.kk)}`})`}
                  </span>
                </span>

                <span
                  title="28 (28.000000000000004%)"
                  className="cdc-legend-item"
                >
                  <span className="rounded-full cdc-legend-item-color bg-tertiary-purple"></span>
                  <span className="">
                    {siteId !== 'tali' ? 'Kalekim Puan ' : 'Kale Puan '}
                    <b>
                      {formatNumber(pointData?.groups?.kkPoint)}
                    </b>
                  </span>
                </span>
                <span title="24 (24%)" className="cdc-legend-item">
                  <span
                    className="rounded-full cdc-legend-item-color"
                    style={{
                      backgroundColor: "#be14d9",
                    }}
                  ></span>
                  <span>
                    Video İzleme{" "}
                    <b>
                      {formatNumber(
                        pointData?.groups?.liveBroadcast?.ks +
                        pointData?.groups?.liveBroadcast?.kk
                      )}
                    </b>
                    {siteId !== "tali" &&
                      `(${`KS ${formatNumber(pointData?.groups?.liveBroadcast?.ks)}, 
                        KK ${formatNumber(pointData?.groups?.liveBroadcast?.kk)}`})`}
                  </span>
                </span>

                <span title="24 (24%)" className="cdc-legend-item">
                  <span className="rounded-full cdc-legend-item-color bg-primary-green"></span>
                  <span>
                    Yarışma{" "}
                    <b>
                      {formatNumber(
                        pointData?.groups?.exam?.ks +
                        pointData?.groups?.exam?.kk
                      )}
                    </b>
                    {siteId !== "tali" &&
                      `(${`KS ${formatNumber(pointData?.groups?.exam?.ks)}, 
                        KK ${formatNumber(pointData?.groups?.exam?.kk)}`})`}
                  </span>
                </span>

                <span title="24 (24%)" className="cdc-legend-item">
                  <span className="rounded-full cdc-legend-item-color bg-primary-yellow"></span>
                  <span>
                    Online Olma ve Site Deneyimi{" "}
                    <b>
                      {formatNumber(
                        pointData?.groups?.beingOnline?.ks +
                        pointData?.groups?.beingOnline?.kk
                      )}
                    </b>
                    {siteId !== "tali" &&
                      `(${`KS ${formatNumber(pointData?.groups?.beingOnline?.ks)}, 
                        KK ${formatNumber(pointData?.groups?.beingOnline?.kk)}`})`}
                  </span>
                </span>

                <span title="24 (24%)" className="cdc-legend-item">
                  <span className="rounded-full cdc-legend-item-color bg-primary-gray"></span>
                  <span>
                    Manuel Puan{" "}
                    <b>
                      {formatNumber(
                        pointData?.groups?.manuel?.ks +
                        pointData?.groups?.manuel?.kk
                      )}
                    </b>
                    {siteId !== "tali" &&
                      `(${`KS ${formatNumber(pointData?.groups?.manuel?.ks)}, 
                        KK ${formatNumber(pointData?.groups?.manuel?.kk)}`})`}
                  </span>
                </span>
                <p className="text-secondary-secondary">
                  {siteId !== "tali" && <span>KS; Kaleseramik | KK; Kalekim</span>}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="inline-block min-w-full">
            <div className="overflow-hidden">
              <table className="min-w-full">
                <thead className="bg-secondary-secondary-bg">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 uppercase font-md text-body-l text-primary-black w-20"
                    >
                      TARİH
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left uppercase font-md text-body-l text-primary-black"
                    >
                      PUAN TÜRÜ
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 uppercase font-md text-body-l text-primary-black w-20"
                    >
                      PUAN
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 uppercase font-md text-body-l text-primary-black w-[50%]"
                    >
                      AÇIKLAMA
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems?.map((record, index) => {
                    if (
                      typeof record.kk_amount !== "number" ||
                      typeof record.ks_amount !== "number" ||
                      (record.resource === "Manuel" &&
                        record.type === "negative")
                    )
                      //eslint-disable-next-line
                      return;
                    return (
                      <tr
                        className="bg-white border-b border-secondary-lines"
                        key={index}
                      >
                        <td className="px-6 py-2 text-center text-body text-secondary-primary">
                          {new Date(record.createdAt).toLocaleDateString()}
                        </td>
                        <td className="px-6 py-2 text-body text-secondary-primary">
                          {record.resource}
                        </td>

                        <td className="px-6 py-2 text-center text-body text-secondary-primary">
                          <p style={{ width: "150px", textAlign: "center" }}>
                            {formatNumber(record.kk_amount + record.ks_amount)}
                          </p>
                          {siteId !== "tali" && (
                            <p
                              style={{
                                width: "75px",
                                fontSize: "12px",
                                textAlign: "left",
                              }}
                            >
                              KK: {formatNumber(record.kk_amount)}
                            </p>
                          )}

                          {siteId !== "tali" && (
                            <p
                              style={{
                                width: "75px",
                                fontSize: "12px",
                                textAlign: "left",
                              }}
                            >
                              KS: {formatNumber(record.ks_amount)}
                            </p>
                          )}

                        </td>
                        <td className="px-6 py-2 text-left text-body text-secondary-primary">
                          {record.description}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Pagination
        totalItems={pointData?.records?.length}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        setCurrentPage={(page) => setCurrentPage(page)}
      />
    </div>
  );
}
export default memo(EarnedPoints);
