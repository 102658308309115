import React from "react";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import {
  TOGGLE_OTHER,
  TOGGLE_PROFILE,
  TOGGLE_SOCIAL,
  TOGGLE_ABOUT,
  TOGGLE_CONTACT,
} from "../../../store/footer/footerSlice";
import { Link } from "react-router-dom";

const FooterPrivate = () => {
  const footerSlice = useSelector((state) => state.footer);
  const dispatch = useDispatch();

  return (
    <footer
      className="bg-secondary-secondary-bg"
      data-testid="footer-component"
    >
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="px-4 pt-12 mx-auto max-w-[1500px] pb-7 lg:px-1">
        <div className="lg:grid lg:grid-cols-3 xl:gap-8">
          {/* <div className="flex flex-col items-center space-y-8 border-b lg:col-span-1 lg:border-none pb-9 lg:pb-0 mb-9 lg:mb-0">
            <div className="inline-flex flex-col text-center">
              <h6 className="text-xs">Kaleseramik</h6>
              <span className="text-center">
                Ramazan Öztürk (İnşaat Mühendisi)
                <br />
                Pazarlama Eğitim Yöneticisi
                <br />
                Kaleseramik Çanakkale Kalebodur Seramik San. A.Ş.
                <br />
                Adres: Levent Mah. Prof. Ahmet Kemal Aru Sok. Kale Holding No: 4
                İç Kapı No: 1 Beşiktaş / İstanbul
                <br />
                P +90 212 371 5252 - M +90 0530 937 0966
                <br />
                Vergi Dairesi: Büyük Mükellefler
                <br />
                Vergi Numarası: 2290004296
              </span>
            </div>
            <div className="inline-flex flex-col text-center">
              <h6 className="text-xs">Kalekim</h6>
              <span>
                Çağlayan Erdemet
                <br />
                Satış Operasyon Digital Uygulamalar
                <br /> Kıdemli Uzmanı
                <br />
                Cep: 5339245640 <br />E mail: caglayanerdemet@kale.com.tr
                <br />
                Vergi Dairesi: Büyük Mükellefler
                <br />
                Vergi Numarası: 4910024958
              </span>
            </div>
          </div> */}
          <div className="flex flex-col items-center space-y-8 border-b lg:col-span-1 lg:border-none pb-9 lg:pb-0 mb-9 lg:mb-0">
            <img alt="" src="/assets/img/logo.png" className="w-24" />

            <div className="inline-flex flex-col text-center">
              <h6 className="text-xs">Kaleseramik</h6>
              <span className="text-center">
                Büyükdere Caddesi Kaleseramik Binası
                <br />
                34330 Levent / İSTANBUL
                <br />
                0850 800 52 53
              </span>
            </div>
            <div className="inline-flex flex-col text-center">
              <h6 className="text-xs">Kalekim</h6>
              <span>
                Firuzköy Mah. Firuzköy Bulvarı No: 188
                <br />
                Avcılar 34325 İstanbul / Türkiye
                <br />
                0850 222 87 82
              </span>
            </div>
            <div className="flex space-x-6">
              <a href="/">
                <span className="sr-only">Facebook</span>
                <svg role="img" className="w-6 h-6">
                  <use xlinkHref="/assets/img/necromancers.svg#facebook" />
                </svg>
              </a>

              <a href="/">
                <span className="sr-only">Twitter</span>
                <svg role="img" className="w-6 h-6">
                  <use xlinkHref="/assets/img/necromancers.svg#twitter" />
                </svg>
              </a>

              <a href="/">
                <span className="sr-only">Instagram</span>
                <svg role="img" className="w-6 h-6">
                  <use xlinkHref="/assets/img/necromancers.svg#instagram" />
                </svg>
              </a>

              <a href="/">
                <span className="sr-only">Youtube</span>
                <svg role="img" className="w-6 h-6">
                  <use xlinkHref="/assets/img/necromancers.svg#youtube" />
                </svg>
              </a>

              <a href="/">
                <span className="sr-only">Linkedin</span>
                <svg role="img" className="w-6 h-6">
                  <use xlinkHref="/assets/img/necromancers.svg#linkedin" />
                </svg>
              </a>
            </div>
          </div>

          <div className="pl-4 pr-2 space-y-8 lg:grid lg:grid-cols-5 lg:gap-8 lg:col-span-2 lg:space-y-0">
            <div className="space-y-6">
              <h3
                onClick={() => dispatch(TOGGLE_ABOUT())}
                className="inline-flex flex-row items-center justify-between w-full cursor-pointer footer-menu-header lg:cursor-default"
              >
                Hakkımızda
                <span
                  className={classNames({
                    "-rotate-90": footerSlice.about,
                    "rotate-90": !footerSlice.about,
                  })}
                >
                  <svg
                    className="w-5 h-5 lg:hidden"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M16 12L9.15113 19L8 17.8235L13.6977 12L8 6.17649L9.15114 5L16 12Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </h3>

              <ul
                className={classNames(" space-y-6 lg:block", {
                  hidden: !footerSlice.about,
                  block: footerSlice.about,
                })}
              >
                <li>
                  <Link to="/about" className="footer-menu-item">
                    Kale Hakkında
                  </Link>
                </li>
                <li>
                  <Link to="/news" className="footer-menu-item">
                    Haberler
                  </Link>
                </li>
                <li>
                  <Link to="/kvkk" className="footer-menu-item">
                    KVKK
                  </Link>
                </li>
                <li>
                  <Link to="/privacy-policy" className="footer-menu-item">
                    Gizlilik Politikası
                  </Link>
                </li>
                {/* <li>
                  <Link to="/terms-of-use" className="footer-menu-item">
                    Kullanım Koşulları
                  </Link>
                </li> */}
                <li>
                  <Link to="/cookie-policy" className="footer-menu-item">
                    Çerez Politikası
                  </Link>
                </li>
              </ul>
            </div>
            <div className="space-y-6">
              <h3
                onClick={() => dispatch(TOGGLE_SOCIAL())}
                className="inline-flex flex-row items-center justify-between w-full cursor-pointer footer-menu-header lg:cursor-default"
              >
                Sosyal Kale
                <span
                  className={classNames({
                    "-rotate-90": footerSlice.social,
                    "rotate-90": !footerSlice.social,
                  })}
                >
                  <svg
                    className="w-5 h-5 lg:hidden"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M16 12L9.15113 19L8 17.8235L13.6977 12L8 6.17649L9.15114 5L16 12Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </h3>
              <ul
                className={classNames(" space-y-6 lg:block", {
                  hidden: !footerSlice.social,
                  block: footerSlice.social,
                })}
              >
                <li>
                  <Link to="/kale-wall" className="footer-menu-item">
                    Kale Duvarı
                  </Link>
                </li>
                <li>
                  <Link to="/gallery" className="footer-menu-item">
                    Galeri
                  </Link>
                </li>
                <li>
                  <Link to="/library" className="footer-menu-item">
                    Kütüphane
                  </Link>
                </li>
                <li>
                  <Link to="/points" className="footer-menu-item">
                    Kale Puan
                  </Link>
                </li>
                <li>
                  <Link to="/friends" className="footer-menu-item">
                    Arkadaşlar
                  </Link>
                </li>
              </ul>
            </div>
            <div className="space-y-6">
              <h3
                onClick={() => dispatch(TOGGLE_CONTACT())}
                className="inline-flex flex-row items-center justify-between w-full cursor-pointer footer-menu-header lg:cursor-default"
              >
                İletişim
                <span
                  className={classNames({
                    "-rotate-90": !footerSlice.contact,
                    "rotate-90": footerSlice.contact,
                  })}
                >
                  <svg
                    className="w-5 h-5 lg:hidden"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M16 12L9.15113 19L8 17.8235L13.6977 12L8 6.17649L9.15114 5L16 12Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </h3>
              <ul
                className={classNames(" space-y-6 lg:block", {
                  hidden: !footerSlice.contact,
                  block: footerSlice.contact,
                })}
              >
                <li>
                  <Link to="/contact" className="footer-menu-item">
                    Bize Ulaşın
                  </Link>
                </li>
                <li>
                  <Link to="/faq" className="footer-menu-item">
                    S.S.S.
                  </Link>
                </li>
              </ul>
            </div>
            <div className="space-y-6">
              <h3
                onClick={() => dispatch(TOGGLE_PROFILE())}
                className="inline-flex flex-row items-center justify-between w-full cursor-pointer footer-menu-header lg:cursor-default"
              >
                Üyelik
                <span
                  className={classNames({
                    "-rotate-90": footerSlice.profile,
                    "rotate-90": !footerSlice.profile,
                  })}
                >
                  <svg
                    className="w-5 h-5 lg:hidden"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M16 12L9.15113 19L8 17.8235L13.6977 12L8 6.17649L9.15114 5L16 12Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </h3>
              <ul
                className={classNames(" space-y-6 lg:block", {
                  hidden: !footerSlice.profile,
                  block: footerSlice.profile,
                })}
              >
                <li>
                  <Link
                    to="/profile/update-profile"
                    className="footer-menu-item"
                  >
                    Bilgi Güncelleme
                  </Link>
                </li>
                <li>
                  <Link to="/change-password" className="footer-menu-item">
                    Şifre Değiştir
                  </Link>
                </li>
                <li>
                  <Link to="/points" className="footer-menu-item">
                    Puanlarım
                  </Link>
                </li>
                <li>
                  <Link to="/profile/surveys" className="footer-menu-item">
                    Anketlerim
                  </Link>
                </li>
                <li>
                  <Link to="/information-cube" className="footer-menu-item">
                    Yarışma
                  </Link>
                </li>
              </ul>
            </div>
            <div className="space-y-6">
              <h3
                onClick={() => dispatch(TOGGLE_OTHER())}
                className="inline-flex flex-row items-center justify-between w-full cursor-pointer footer-menu-header lg:cursor-default"
              >
                Diğer
                <span
                  className={classNames({
                    "-rotate-90": footerSlice.other,
                    "rotate-90": footerSlice.other,
                  })}
                >
                  <svg
                    className="w-5 h-5 lg:hidden"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M16 12L9.15113 19L8 17.8235L13.6977 12L8 6.17649L9.15114 5L16 12Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </h3>
              <ul
                className={classNames(" space-y-6 lg:block", {
                  hidden: !footerSlice.other,
                  block: footerSlice.other,
                })}
              >
                <li>
                  <Link to="/activity" className="footer-menu-item">
                    Etkinlik Takvimi
                  </Link>
                </li>
                <li>
                  <Link to="/consumption-calc" className="footer-menu-item">
                    Tüketim Hesaplama
                  </Link>
                </li>
                <li>
                  <Link to="/live-stream" className="footer-menu-item">
                    Canlı Yayın
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-7xl">
        <div className="p-6 mb-16 border-t border-secondary-lines lg:mb-0">
          <p className="text-base text-center text-primary-black">
            © {new Date().getFullYear()} Kale Satış Kulübü
          </p>
        </div>
      </div>
    </footer>
  );
};

export default FooterPrivate;
